import { BaseModel } from "./base.model";
import { User } from "./user.model";

export class SocialPost extends BaseModel {
  user?: User;
  socialposts?: SocialPost[];
  constructor(
    public post: string,
    public user_id: string,
    public attachment_name?: string,
    public attachment_url?: string,
    public attachment_type?: string,
    public deleted_at?: string,
    public social_post_topic_id?: string,
    public social_post_id?: string,
    public is_liked?: boolean,
    public likes_count: number = 0
  ) {
    super();
  }
}
