

















import WritePost from "@/components/landing/WritePost.vue";
import PostView from "@/components/landing/PostView.vue";
import Pusher, { Channel } from "pusher-js"; // import Pusher
import { SocialPost } from "@/models/social-post.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "@/models/user.model";
import { SocialpostTopic } from "@/models/socialpost-topic.model";

const socialPostsModule = namespace("SocialPosts");
const authModule = namespace("Auth");

@Component({ components: { WritePost, PostView } })
export default class SocialWall extends Vue {
  isLoadingNext = false;
  isLoadingFirst = false;
  pusherChannel?: Channel;

  @Prop()
  hideScroll!: boolean;

  @Prop()
  topic!: string | SocialpostTopic;

  @authModule.Getter("currentUser")
  currentUser!: User;

  @socialPostsModule.Getter("all")
  posts!: SocialPost[];

  @socialPostsModule.Getter("hasNext")
  hasNext!: boolean;

  @socialPostsModule.Mutation("PREPEND_POST")
  prependSocialPost!: (socialPost: SocialPost) => void;

  @socialPostsModule.Action("fetchNext")
  fetchNext!: () => Promise<void>;

  @socialPostsModule.Action("fetchAll")
  fetchAll!: () => Promise<SocialPost[]>;

  get scrollClass() {
    return this.hideScroll ? "" : "scrollableSocial";
  }

  get topicId() {
    return typeof this.topic === "string" ? this.topic : this.topic.id;
  }

  postAlreadyLoaded(socialPost: SocialPost) {
    return Boolean(
      this.posts.find((item) => {
        return item.id === socialPost.id;
      })
    );
  }

  isPostOfCurrentUser(socialPost: SocialPost) {
    return Boolean(this.currentUser?.id === socialPost.user?.id);
  }

  isPostOfCurrentTopic(socialPost: SocialPost) {
    return Boolean(this.topicId === socialPost.social_post_topic_id);
  }

  async doFetchNext() {
    this.isLoadingNext = true;
    await this.fetchNext();
    this.isLoadingNext = false;
  }

  updatePosts() {
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
      cluster: "eu",
    });
    this.pusherChannel = pusher.subscribe("socialposts");
    this.pusherChannel.bind(window.origin, (data) => {
      const post = data.SocialPost;
      if (
        this.postAlreadyLoaded(post) ||
        this.isPostOfCurrentUser(post) ||
        !this.isPostOfCurrentTopic(post)
      ) {
        return;
      }
      this.isLoadingFirst = true;
      this.prependSocialPost(data.SocialPost);
      setTimeout(() => {
        this.isLoadingFirst = false;
      }, 500);
    });
  }

  async created() {
    this.isLoadingFirst = true;
    await this.$store.dispatch("SocialPosts/fetchAll", this.topicId);
    this.isLoadingFirst = false;
    this.updatePosts();
  }

  destroyed() {
    this.pusherChannel?.unbind();
  }
}
