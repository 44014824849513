



















































































































































import { SocialPost } from "@/models/social-post.model";
import WritePost from "./WritePost.vue";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ListGroupItemLike from "@/components/landing/ListGroupItemLike.vue";

@Component({
  name: "PostView",
  components: { WritePost, PostView, ListGroupItemLike },
})
export default class PostView extends Vue {
  isLoading = false;
  isLiking = false;
  openReply = false;
  showReplies = false;
  showLikes = false;
  isLoadingLikes = false;

  @Prop()
  post!: SocialPost;

  @Prop()
  index!: number;

  get currentUser() {
    return this.$store.getters["Auth/currentUser"];
  }

  get likes() {
    return this.$store.getters["SocialPosts/likes"];
  }

  get isLiked() {
    return this.post?.is_liked;
  }

  get userName() {
    if (!this.post.user) {
      return "Unknown";
    }
    return `${this.post.user.first_name} ${this.post.user.last_name}`;
  }

  get userInitials() {
    return `${this.post?.user?.first_name?.charAt(
      0
    )}${this.post?.user?.last_name?.charAt(0)}`;
  }

  get profilePicture() {
    return this.post?.user?.profile_image_url;
  }

  get hasImage() {
    return this.post.attachment_type === "image";
  }

  get attachmentUrl() {
    return process.env.VUE_APP_FILE_URL + this.post.attachment_url;
  }
  get attachmentName() {
    return this.post.attachment_name;
  }

  get hasReplies() {
    return this.numberOfReplies > 0;
  }

  get numberOfReplies() {
    return this.post?.socialposts?.length || 0;
  }

  get replies() {
    return this.post?.socialposts;
  }

  get hasParentPost() {
    return Boolean(this.post?.social_post_id);
  }

  get topic() {
    return this.post?.social_post_topic_id;
  }

  get timestamp() {
    return this.$t(moment(this.post?.created_at).fromNow());
  }

  async like() {
    this.isLiking = true;
    await this.$store.dispatch("SocialPosts/like", this.post.id);
    this.isLiking = false;
  }

  async unlike() {
    this.isLiking = true;
    await this.$store.dispatch("SocialPosts/unlike", this.post.id);
    this.isLiking = false;
  }

  async doShowLikes() {
    this.isLoadingLikes = true;
    this.showLikes = true;
    await this.$store.dispatch("SocialPosts/likes", this.post.id);
    this.isLoadingLikes = false;
  }
  async deletePost() {
    const result = await this.$bvModal.msgBoxConfirm(
      "Are you sure you want to delete this post?",
      {
        title: "Confirm delete",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "Delete",
        cancelTitle: "Cancel",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      }
    );
    if (result) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("SocialPosts/delete", this.post.id);
      } catch (e) {
        console.log(e);
      }
      this.isLoading = false;
    }
  }

  onShowReplies() {
    this.showReplies = true;
  }

  onHideReplies() {
    this.showReplies = false;
  }

  onOpenReply() {
    this.openReply = true;
  }
}
