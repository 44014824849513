


























































































import { SocialPost } from "@/models/social-post.model";
import axios from "@/plugins/axios.plugin";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";

@Component({ components: {} })
export default class WritePost extends Vue {
  fileUrl = null;
  attachmentName = "";
  imageUploaded = false;
  uploadingFile = false;
  creatingPost = false;
  attachmentUploaded = false;
  post = new SocialPost("", "");
  componentUuid = uuidv4();

  @Prop()
  topic!: string;

  @Prop({ required: false })
  parentPost!: string;

  get currentUser() {
    return this.$store.getters["Auth/currentUser"];
  }
  get userId() {
    return this.currentUser?.id;
  }

  get imageId() {
    return `image-${this.componentUuid}`;
  }

  get attachmentId() {
    return `attachment-${this.componentUuid}`;
  }

  chooseImage() {
    document.getElementById(this.imageId)?.click();
  }

  chooseAttachment() {
    document.getElementById(this.attachmentId)?.click();
  }

  async onImageChange(event) {
    this.attachmentUploaded = false;
    this.imageUploaded = true;
    await this.uploadFile(event.target.files[0], "image");
  }

  async onAttachmentChange(event) {
    this.imageUploaded = false;
    this.attachmentUploaded = true;
    await this.uploadFile(event.target.files[0], "file");
  }

  async uploadFile(file, type) {
    this.uploadingFile = true;
    let formData = new FormData();
    formData.append("file", file);
    const response = await axios.post("/files/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      },
      responseType: "json",
    });
    this.fileUrl = process.env.VUE_APP_FILE_URL + response.data.url;
    this.post.attachment_type = type;
    this.post.attachment_url = response.data.url;
    this.post.attachment_name = file.name;
    this.uploadingFile = false;
  }

  async createPost() {
    this.creatingPost = true;
    this.post.user_id = this.userId;
    this.post.social_post_id = this.parentPost;
    this.post.social_post_topic_id = this.topic;
    await this.$store.dispatch("SocialPosts/create", this.post);
    this.post = new SocialPost("", "");
    this.creatingPost = false;
  }
}
