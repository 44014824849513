












import { SocialPostLike } from "@/models/social-post-like.model";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ListGroupItemLike extends Vue {
  @Prop()
  like!: SocialPostLike;

  get username() {
    if (!this.like?.user) {
      return this.$t("N/A");
    }
    return `${this.like?.user?.first_name} ${this.like?.user?.last_name}`;
  }

  get userInitials() {
    return `${this.like?.user?.first_name?.charAt(
      0
    )}${this.like?.user?.last_name?.charAt(0)}`;
  }

  get profilePicture() {
    return this.like?.user?.profile_image_url;
  }
}
